import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6cf00769")
const _hoisted_1 = { class: "empower-step" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_empowerStep = _resolveComponent("empowerStep", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_empowerStep, {
      options: _ctx.JudgeOpinions,
      curStatus: _ctx.status,
      failStatus: 2,
      passStatus: 21
    }, null, 8, ["options", "curStatus"])
  ]))
}